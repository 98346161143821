import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { singletonHook } from "react-singleton-hook";

interface UrlResult {
    pathname: string;
}

interface IAppUrlBuilder {
    home: {
        HomeUrl(qrCodeId?: string): UrlResult;
        ScanCodeUrl(): UrlResult;
    };
    auth: {
        LoginUrl(): UrlResult;
        RegisterUrl(): UrlResult;
        ForgotPasswordUrl(): UrlResult;
        ForgotPasswordResultUrl(): UrlResult;
        ConfirmEmailUrl(): UrlResult;
        ResetPasswordUrl(): UrlResult;
        ResetPasswordResultUrl(): UrlResult;
    },
    profile: {
        ProfileUrl(): UrlResult;
        SettingsUrl(): UrlResult;
        TransactionsUrl(): UrlResult;
        ChangePasswordUrl(): UrlResult;
        EditProfileUrl(): UrlResult;
        WalletTopUpUrl(): UrlResult;
    },
    order: {
        Orders(): UrlResult;
        TrackOrder(orderId?: string): UrlResult;
        CartUrl(): UrlResult;
        Checkout(): UrlResult;
    },
    payment: {
        ChargeCompleted(chargeId?: string): UrlResult;
        PaymentMethodsUrl(isFreePayment?: boolean): UrlResult;
        PaymentSummaryUrl(): UrlResult;
        PayTotalUrl(): UrlResult;
        ShareEqualUrl(): UrlResult;
        ShareItemsUrl(): UrlResult;
        ShareCustomUrl(): UrlResult;
        FreePaymentUrl(): UrlResult;
        PayTopUpPage(chargeId?: string): UrlResult;
        PayPage(chargeId?: string, preceddingChargeId?: string): UrlResult;
        PayOrderPage(params?: {orderId: string, chargeId: string, preceddingChargeId?: string}): UrlResult;
        methods: {
            Cash(): UrlResult;
        }
    },
    menu: {
        ExternalMenu(): UrlResult;
        PhysicalMenu(): UrlResult;
        DigitalMenu(props?: {timestamp?: number}): UrlResult;
    },
}

interface IAppNavigator {
    goTo(urlBuilder: (navigator: IAppUrlBuilder) => UrlResult, replaceHistory?: boolean): void;
    goBack(urlBuilder?: (navigator: IAppUrlBuilder) => UrlResult, replaceHistory?: boolean): void;
    goForward(): void;
    canGoBack(): boolean;
    urlBuilder: IAppUrlBuilder;
}

const urlBuilderObj: IAppUrlBuilder = {
    home: {
        HomeUrl(qrCodeId?: string): UrlResult {
            if (!qrCodeId)
                return { pathname: `/` };
            return { pathname: `/?id=${qrCodeId}` };
        },
        ScanCodeUrl(): UrlResult {
            return { pathname: "/scancode" };
        }, 
    },
    auth: {
        LoginUrl(): UrlResult {
            return { pathname: "/login" };
        },
        RegisterUrl(): UrlResult {
            return { pathname: "/register" };
        },
        ConfirmEmailUrl(): UrlResult {
            return { pathname: "/confirmemail" };
        },
        ForgotPasswordUrl(): UrlResult {
            return { pathname: "/forgotpassword" };
        },
        ForgotPasswordResultUrl(): UrlResult {
            return { pathname: "/forgotpasswordresult" };
        },
        ResetPasswordUrl(): UrlResult {
            return { pathname: "/resetpassword" };
        },
        ResetPasswordResultUrl(): UrlResult {
            return { pathname: "/resetpasswordresult" };
        },
    },
    profile: {
        ProfileUrl(): UrlResult {
            return { pathname: "/user/home" };
        },
        SettingsUrl(): UrlResult {
            return { pathname: "/user/settings" };
        },
        TransactionsUrl(): UrlResult {
            return { pathname: "/user/transactions" };
        },
        ChangePasswordUrl(): UrlResult {
            return { pathname: "/user/settings/changepassword" };
        },
        EditProfileUrl(): UrlResult {
            return { pathname: "/user/settings/editprofile" };
        },
        WalletTopUpUrl(): UrlResult {
            return { pathname: "/user/wallet/topup" };
        }
    },
    order: {
        Orders(): UrlResult {
            return { pathname: "/orders" };
        },
        TrackOrder(orderId?: string): UrlResult {
            return { 
                pathname: `/order/${orderId ?? ':orderId'}/track`,
            };
        },
        CartUrl(): UrlResult {
            return { pathname: "/cart" };
        },
        Checkout(): UrlResult {
            return { pathname: "/pay/checkout" };
        },
    },
    payment: {
        ChargeCompleted(chargeId?: string): UrlResult {
            return { pathname: `/charge/${chargeId ?? ':chargeId'}` };
        },
        PaymentMethodsUrl(isFreePayment?: boolean): UrlResult {
            if(isFreePayment == true) {
                return { pathname: "/pay/paymentmethods?freePayment=1" };
            }
            return { pathname: "/pay/paymentmethods" };
        },
        PaymentSummaryUrl(): UrlResult {
            return { pathname: "/pay/summary" };
        },
        PayTotalUrl(): UrlResult {
            return { pathname: "/pay/PayTotal" };
        },
        ShareEqualUrl(): UrlResult {
            return { pathname: "/pay/ShareEqual" };
        },
        ShareItemsUrl(): UrlResult {
            return { pathname: "/pay/ShareItems" };
        },
        ShareCustomUrl(): UrlResult {
            return { pathname: "/pay/ShareCustom" };
        },
        FreePaymentUrl(): UrlResult {
            return { pathname: "/pay/FreePayment" };
        },
        PayTopUpPage(chargeId?: string): UrlResult {
            const basePath = "/pay/topup";
            return { 
                pathname: !chargeId ? `${basePath}/:chargeId` : `${basePath}/${chargeId}`,
            };
        },
        PayPage(chargeId?: string): UrlResult {
            const basePath = "/pay/session";
            return { 
                pathname: !chargeId ? `${basePath}/:chargeId` : `${basePath}/${chargeId}`,
            };
        },
        PayOrderPage(params?: {orderId: string, chargeId: string}): UrlResult {
            return { 
                pathname: `/pay/order/${params?.orderId ?? ':orderId'}/${params?.chargeId ?? ':chargeId'}`,
            };
        },
        methods: {
            Cash(): UrlResult {
                return { pathname: "/pay/method/Cash" };
            }
        }
    },
    menu: {
        ExternalMenu(): UrlResult {
            return { pathname: "/external" };
        },
        PhysicalMenu(): UrlResult {
            return { pathname: "/menu" };
        },
        DigitalMenu(props?: {timestamp?: number}): UrlResult {
            let path = "/order";
            if(props?.timestamp != undefined) {
                path = `${path}?timestamp=${props.timestamp}`;
            }
            return { pathname: path}; 
        },
    },
};

const initialState = {
    goTo(urlBuilder: (builder: IAppUrlBuilder) => UrlResult, replaceHistory?: boolean): void {
    },
    goBack(urlBuilder?: (builder: IAppUrlBuilder) => UrlResult, replaceHistory?: boolean): void {
    },
    goForward(): void {
    },
    canGoBack(): boolean {
        return false;
    },
    urlBuilder: urlBuilderObj,
};
export const useAppNavigation = singletonHook<IAppNavigator>(initialState, (): IAppNavigator => {
    const history = useHistory();
    let navigationHistory: string[] = [];
    
    const trackPageView = () => {
        if(navigationHistory.length > 0)
        {
            if(navigationHistory[navigationHistory.length - 1] == `${history.location.pathname}${history.location.search}`) {
                return;
            }
        }

        const n = `${history.location.pathname}${history.location.search}`
        if(history.action == "REPLACE" && navigationHistory.length > 0) {
            navigationHistory[navigationHistory.length - 1] = n;
            return;
        }
        
        navigationHistory.push(n);
    }

    useEffect(() => {
        if(!history) {
            return;
        }
        navigationHistory = [`${history.location.pathname}${history.location.search}`];
        const c = history.listen(trackPageView);
        return () => c();
    }, [history])

    const [appNavigation] = useState<IAppNavigator>(() => ({
        goTo(urlBuilder: (builder: IAppUrlBuilder) => UrlResult, replaceHistory?: boolean): void {
            const urlResult = urlBuilder(urlBuilderObj);
            
            if (replaceHistory === true) {
                for(let i = 0; i < navigationHistory.length; ++i) {
                    navigationHistory.pop() 
                }
                history.replace(urlResult.pathname);
            }
            else {
                history.push(urlResult.pathname);
            }
        },
        goBack(urlBuilder?: (builder: IAppUrlBuilder) => UrlResult, replaceHistory?: boolean): void {
            if(this.canGoBack()) {
                navigationHistory.pop();
                history.goBack();
                return;
            }

            if(urlBuilder == undefined) {
                return;
            }

            const urlResult = urlBuilder(urlBuilderObj);
            history.replace(urlResult.pathname);
        },
        goForward(): void {
            history.goForward();
        },
        canGoBack(): boolean {
            return navigationHistory.length > 1;
        },
        urlBuilder: urlBuilderObj,
    }));

    return appNavigation;
});