import { HubConnection, HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";
import { SIGNALR_HOST_URL } from "../../constants";
import { IWebClient } from "./useWebEvents";
import { IChargeListener } from "./listeners/IChargeListener";
import { IQrCodeListener } from "./listeners/IQrCodeListener";
import { IMerchantListener } from "./listeners/IMerchantListener";
import { IOrderChangedEvent } from "./models/IOrderChangedEvent";
import { IChargeSyncedEvent } from "./models/IChargeSyncedEvent";
import { IChargeStatusChangedEvent } from "./models/IChargeStatusChangedEvent";
import { ISessionChangedEvent } from "./models/ISessionChangedEvent";
import { IQrCodeChangedEvent } from "./models/IQrCodeChangedEvent";
import { IMerchantConnectionChangedEvent } from "./models/IMerchantConnectionChangedEvent";
import { IMerchantChangedEvent } from "./models/IMerchantChangedEvent";
import { ITopUpListener } from "./listeners/ITopUpListener";
import { IMenuItemListener } from "./listeners/IMenuItemListener";
import { ITopUpEvent } from "./models/ITopUpEvent";
import { IMenuItemChangedEvent } from "./models/IMenuItemChangedEvent";
import { IMenuItemAddedEvent } from "./models/IMenuItemAddedEvent";
import { IMenuItemDeletedEvent } from "./models/IMenuItemDeletedEvent";
import { IMenuItemAvailabilityChangedEvent } from "./models/IMenuItemAvailabilityChangedEvent";
import { IJobListener } from "./listeners/IJobListener";
import { IJobChangedEvent } from "./models/IJobChangedEvent";
import { IConsumerBalanceChangedEvent } from "./models/IConsumerBalanceChangedEvent";
import { IConsumerBalanceListener } from "./listeners/IConsumerBalanceListener";
import { IOrderCommitedEvent } from "./models/IOrderCommitedEvent";
import { ISessionAddedEvent } from "./models/ISessionAddedEvent";
import { IDeGrazieListener } from "./listeners/IDeGrazieListener";
import { IInvoiceChangedEvent } from "./models/IInvoiceChangedEvent";
import { OrderFieldDeletedEvent } from "./models/OrderFieldDeletedEvent";
import { OrderFieldChangedEvent } from "./models/OrderFieldChangedEvent";
import { OrderFieldAddedEvent } from "./models/OrderFieldAddedEvent";

export class SignalRClient implements IWebClient {
    public readonly connection: HubConnection = new HubConnectionBuilder()
        .withUrl(`${SIGNALR_HOST_URL}WebConsumer`)
        .build();

    public readonly deGrazieListeners: Set<IDeGrazieListener> = new Set<IDeGrazieListener>();
    public readonly chargeListeners: Set<IChargeListener> = new Set<IChargeListener>();
    public readonly qrCodeListeners: Set<IQrCodeListener> = new Set<IQrCodeListener>();
    public readonly merchantListeners: Set<IMerchantListener> = new Set<IMerchantListener>();
    public readonly topUpListeners: Set<ITopUpListener> = new Set<ITopUpListener>();
    public readonly consumerBalanceListners: Set<IConsumerBalanceListener> = new Set<IConsumerBalanceListener>();
    public readonly menuItemListeners: Set<IMenuItemListener> = new Set<IMenuItemListener>();
    public readonly jobListeners: Set<IJobListener> = new Set<IJobListener>();

    addDeGrazieListener(listener: IDeGrazieListener): void {
        let listeners = this.deGrazieListeners;
        if(listeners.has(listener)) {
            return;
        }

        listeners.add(listener);
    }
    removeDeGrazieListener(listener: IDeGrazieListener): void {
        let listeners = this.deGrazieListeners;
        listeners.delete(listener);
    }

    addChargeListener(listener: IChargeListener): void {
        let listeners = this.chargeListeners;
        if(listeners.has(listener)) {
            return;
        }

        listeners.add(listener);
        if (this.connection.state == HubConnectionState.Connected) {
            this.connection.invoke("JoinChargeEvents", listener.chargeId);
        }
    }
    removeChargeListener(listener: IChargeListener): void {
        let listeners = this.chargeListeners;
        if (listeners.delete(listener) && this.connection.state == HubConnectionState.Connected) {
            for (let item of listeners.values()) {
                if (item.chargeId == listener.chargeId)
                    return;
            }
            this.connection.invoke("LeaveChargeEvents", listener.chargeId);
        }
    }

    addQrCodeListener(listener: IQrCodeListener): void {
        let listeners = this.qrCodeListeners;
        if(listeners.has(listener)) {
            return;
        }

        listeners.add(listener);
        if (this.connection.state == HubConnectionState.Connected) {
            this.connection.invoke("JoinQrCodeEvents", listener.qrCodeId);
        }
    }
    removeQrCodeListener(listener: IQrCodeListener): void {
        let listeners = this.qrCodeListeners;
        if (listeners.delete(listener) && this.connection.state == HubConnectionState.Connected) {
            for (let item of listeners.values()) {
                if (item.qrCodeId == listener.qrCodeId)
                    return;
            }
            this.connection.invoke("LeaveQrCodeEvents", listener.qrCodeId);
        }
    }

    addMerchantListener(listener: IMerchantListener): void {
        let listeners = this.merchantListeners;
        if(listeners.has(listener)) {
            return;
        }

        listeners.add(listener);
        if (this.connection.state == HubConnectionState.Connected) {
            this.connection.invoke("JoinMerchantEvents", listener.merchantId);
        }
    }
    removeMerchantListener(listener: IMerchantListener): void {
        let listeners = this.merchantListeners;
        if (listeners.delete(listener) && this.connection.state == HubConnectionState.Connected) {
            for (let item of listeners.values()) {
                if (item.merchantId == listener.merchantId)
                    return;
            }
            this.connection.invoke("LeaveMerchantEvents", listener.merchantId);
        }
    }

    addTopUpListener(listener: ITopUpListener): void {
        let listeners = this.topUpListeners;
        if(listeners.has(listener)) {
            return;
        }

        listeners.add(listener);
        if (this.connection.state == HubConnectionState.Connected) {
            this.connection.invoke("JoinWalletEvents");
        }
    }
    removeTopUpListener(listener: ITopUpListener): void {
        let listeners = this.topUpListeners;
        if (listeners.delete(listener) && this.connection.state == HubConnectionState.Connected) {
            for (let item of listeners.values()) {
                return;
            }
            this.connection.invoke("LeaveWalletEvents");
        }
    }

    addConsumerBalanceListner(listener: IConsumerBalanceListener): void {
        let listeners = this.consumerBalanceListners;
        if(listeners.has(listener)) {
            return;
        }

        listeners.add(listener);
        if (this.connection.state == HubConnectionState.Connected) {
            this.connection.invoke("JoinConsumerBalanceEvents");
        }
    }
    removeConsumerBalanceListener(listener: IConsumerBalanceListener): void {
        let listeners = this.consumerBalanceListners;
        if (listeners.delete(listener) && this.connection.state == HubConnectionState.Connected) {
            for (let item of listeners.values()) {
                return;
            }
            this.connection.invoke("LeaveConsumerBalanceEvents");
        }
    }
    
    addMenuItemListener(listener: IMenuItemListener): void {
        let listeners = this.menuItemListeners;
        if(listeners.has(listener)) {
            return;
        }

        listeners.add(listener);
        if (this.connection.state == HubConnectionState.Connected) {
            this.connection.invoke("JoinMenuItemEvents", listener.merchantId);
        }
    }
    removeMenuItemListener(listener: IMenuItemListener): void {
        let listeners = this.menuItemListeners;
        if (listeners.delete(listener) && this.connection.state == HubConnectionState.Connected) {
            for (let item of listeners.values()) {
                return;
            }
            this.connection.invoke("LeaveMenuItemEvents", listener.merchantId);
        }
    }

    addJobListener(listener: IJobListener): void {
        let listeners = this.jobListeners;
        if(listeners.has(listener)) {
            return;
        }

        listeners.add(listener);
        if (this.connection.state == HubConnectionState.Connected) {
            this.connection.invoke("JoinJobEvents", listener.jobId);
        }
    }
    removeJobListener(listener: IJobListener): void {
        let listeners = this.jobListeners;
        if (listeners.delete(listener) && this.connection.state == HubConnectionState.Connected) {
            for (let item of listeners.values()) {
                if (item.jobId == listener.jobId)
                    return;
            }
            this.connection.invoke("LeaveJobEvents", listener.jobId);
        }
    }

    connectToChannels() {
        this.connection.off('OnOrderChanged');
        this.connection.on('OnOrderChanged', (evt: IOrderChangedEvent) => this.qrCodeListeners.forEach(l => l.qrCodeId == evt.qrCodeId && l.onOrderChangedEvent?.(evt)));
        
        this.connection.off('OnOrderCommited');
        this.connection.on('OnOrderCommited', (evt: IOrderCommitedEvent) => this.qrCodeListeners.forEach(l => l.qrCodeId == evt.qrCodeId && l.onOrderCommitedEvent?.(evt)));


        this.connection.off('OnInvoiceAdded');
        this.connection.on('OnInvoiceAdded', (evt: IInvoiceChangedEvent) => this.chargeListeners.forEach((l: IChargeListener) => l.chargeId == evt.chargeId && l.onInvoiceChangedEvent(evt)));

        this.connection.off('OnInvoiceChanged');
        this.connection.on('OnInvoiceChanged', (evt: IInvoiceChangedEvent) => this.chargeListeners.forEach((l: IChargeListener) => l.chargeId == evt.chargeId && l.onInvoiceChangedEvent(evt)));


        this.connection.off('OnSessionAdded');
        this.connection.on('OnSessionAdded', (evt: ISessionAddedEvent) => this.qrCodeListeners.forEach((l: IQrCodeListener) => l.qrCodeId == evt.qrCodeId && l.onSessionAddedEvent?.(evt)));

        this.connection.off('OnSessionChanged');
        this.connection.on('OnSessionChanged', (evt: ISessionChangedEvent) => this.qrCodeListeners.forEach(l => l.qrCodeId == evt.qrCodeId && l.onSessionChangedEvent?.(evt)));


        this.connection.off('OnQrCodeChanged');
        this.connection.on('OnQrCodeChanged', (evt: IQrCodeChangedEvent) => this.qrCodeListeners.forEach((l: IQrCodeListener) => l.qrCodeId == evt.qrCodeId && l.onQrCodeChangedEvent?.(evt)));
       
        this.connection.off('OnChargeSynced');
        this.connection.on('OnChargeSynced', (evt: IChargeSyncedEvent) => this.chargeListeners.forEach((l: IChargeListener) => l.chargeId == evt.chargeId && l.onChargeSyncedEvent(evt)));

        this.connection.off('OnChargeStatusChanged');
        this.connection.on('OnChargeStatusChanged', (evt: IChargeStatusChangedEvent) => this.chargeListeners.forEach((l: IChargeListener) => l.chargeId == evt.chargeId && l.onChargeStatusChangedEvent(evt)));


        this.connection.off('OnOrderFieldAdded');
        this.connection.on('OnOrderFieldAdded', (evt: OrderFieldAddedEvent) => this.merchantListeners.forEach(l => l.merchantId == evt.merchantId && l.onOrderFieldAdded?.(evt)));
        
        this.connection.off('OnOrderFieldChanged');
        this.connection.on('OnOrderFieldChanged', (evt: OrderFieldChangedEvent) => this.merchantListeners.forEach(l => l.merchantId == evt.merchantId && l.onOrderFieldChanged?.(evt)));
        
        this.connection.off('OnOrderFieldDeleted');
        this.connection.on('OnOrderFieldDeleted', (evt: OrderFieldDeletedEvent) => this.merchantListeners.forEach(l => l.merchantId == evt.merchantId && l.onOrderFieldDeleted?.(evt)));


        this.connection.off('OnMerchantConnectionChanged');
        this.connection.on('OnMerchantConnectionChanged', (evt: IMerchantConnectionChangedEvent) => this.merchantListeners.forEach(l => l.merchantId == evt.merchantId && l.onMerchantConnectionChangedEvent?.(evt)));

        this.connection.off('OnMerchantChanged');
        this.connection.on('OnMerchantChanged', (evt: IMerchantChangedEvent) => this.merchantListeners.forEach(l => l.merchantId == evt.merchantId && l.onMerchantChanged?.(evt)));


        this.connection.off('OnTopUp');
        this.connection.on('OnTopUp', (evt: ITopUpEvent) => this.topUpListeners.forEach((l: ITopUpListener) => l.onTopUp(evt)));


        this.connection.off('OnConsumerBalanceChanged');
        this.connection.on('OnConsumerBalanceChanged', (evt: IConsumerBalanceChangedEvent) => this.consumerBalanceListners.forEach(l => l.onConsumerBalanceChanged(evt)));


        this.connection.off('OnMenuItemChanged');
        this.connection.on('OnMenuItemChanged', (evt: IMenuItemChangedEvent) => this.menuItemListeners.forEach(l => l.onMenuItemChanged?.(evt)));

        this.connection.off('OnMenuItemAdded');
        this.connection.on('OnMenuItemAdded', (evt: IMenuItemAddedEvent) => this.menuItemListeners.forEach(l => l.onMenuItemAdded?.(evt)));

        this.connection.off('OnMenuItemDeleted');
        this.connection.on('OnMenuItemDeleted', (evt: IMenuItemDeletedEvent) => this.menuItemListeners.forEach(l => l.onMenuItemDeleted?.(evt)));

        this.connection.off('OnMenuItemAvailabilityChanged');
        this.connection.on('OnMenuItemAvailabilityChanged', (evt: IMenuItemAvailabilityChangedEvent) => this.menuItemListeners.forEach(l => l.onMenuItemAvailabilityChanged?.(evt)));

        this.connection.off('OnJobChanged');
        this.connection.on('OnJobChanged', (evt: IJobChangedEvent) => this.jobListeners.forEach(l => l.OnJobChanged?.(evt)));

        this.chargeListeners.forEach(l => this.connection.invoke('JoinChargeEvents', l.chargeId));
        this.qrCodeListeners.forEach(l => this.connection.invoke('JoinQrCodeEvents', l.qrCodeId));
        this.merchantListeners.forEach(l => this.connection.invoke('JoinMerchantEvents', l.merchantId));
        this.topUpListeners.forEach(l => this.connection.invoke('JoinWalletEvents')); 
        this.consumerBalanceListners.forEach(l => this.connection.invoke('JoinConsumerBalanceEvents'));
        this.jobListeners.forEach(l => this.connection.invoke('JoinJobEvents', l.jobId));
    }

    setJwt(jwt: string | null) {
        if(jwt == null) {
            this.connection.baseUrl = `${SIGNALR_HOST_URL}WebConsumer`;
        } else {
            this.connection.baseUrl = `${SIGNALR_HOST_URL}WebConsumer?access_token=${jwt}`;
        }
    }
}