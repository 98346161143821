import { Link, useParams } from "react-router-dom";
import OrderAndPaySuccess from "./Success/OrderAndPaySuccess";
import { OrderState } from "../../app/services/api/contracts/models/orderState";
import OrderAndPayCanceled from "./Success/OrderAndPayCanceled";
import { useTranslation } from "react-i18next";
import LoadingAnimation from "../../components/LoadingAnimation/LoadingAnimation";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import SquareButton from "../../components/Shared/SquareButton";
import { useDeGrazieTheme } from "../../app/hooks/theme/useDeGrazieTheme";
import { shadeColor } from "../../app/helpers/colorHelper";
import { useAppNavigation } from "../../app/hooks/useAppNavigation";
import { Page } from "../../layout/Page";
import { ButtonsSection } from "../../layout/ButtonsSection";
import { useOrdersQuery } from "../../app/hooks/menus/order/useOrdersQuery";
import { useOrders } from "../../app/hooks/menus/order/useOrders";

interface AutoCloseProps {
    readonly startAt: number;
    readonly percentage: number;
}
const getPercentage = (startAt: number, duration: number) => {
    const now = new Date().getTime();
    const ex = now - startAt;

    return 100 - (ex > duration ? 0 : ex * 100 / duration);
}
const autoCloseDuration = 10000;
const TrackOrderPage = () => {
    const { t } = useTranslation();
    const { orderId } = useParams<{orderId: string}>();
    const orderQuery = useOrdersQuery(!orderId ? undefined : {
        ids: [orderId]
    });
    const theme = useDeGrazieTheme();
    const appNavigation = useAppNavigation();
    const order = orderQuery.isFirstLoading ? undefined : orderQuery.data.length > 0 ? orderQuery.data[0] : undefined;
    const orders = useOrders();

    const features = useAppSelector(state => state.merchant.features);
    const isAuth = useAppSelector(state => state.user.isAuth);

    const [autoCloseState, setAutoCloseState] = useState<AutoCloseProps>();

    useEffect(() => {
        if(autoCloseState == undefined) {
            return;
        }

        if(autoCloseState.percentage == 100) {
            appNavigation.goTo(t => t.home.HomeUrl(order?.qrCodeId));
            return;
        }

        const interval = setTimeout(() => setAutoCloseState(p => p == undefined ? undefined :({
            ...p,
            percentage: getPercentage(p.startAt, autoCloseDuration),
        })), 10);
        return () => clearTimeout(interval);
    }, [autoCloseState])
    
    useEffect(() => {
        if(order == undefined) {
            return;
        }

        if(features.physicalKiosk == false) {
            return;
        }

        setAutoCloseState({
            percentage: 0,
            startAt: new Date().getTime(),
        })
    }, [order, features])

    const getSecondsLeft = (state: AutoCloseProps) => Math.max((autoCloseDuration - (new Date().getTime() - state.startAt)) / 1000, 0);

    const darkenColor = shadeColor(theme.primaryColor, 35);

    const getFooter = () => {
        if(order == undefined) {
            return;
        }
        if(autoCloseState == undefined) {
            if(isAuth == false) {
                return;
            }
            return <ButtonsSection>
                <Link to={appNavigation.urlBuilder.home.HomeUrl()} className="secondary-button mb-4">{t("paymentResult.home")}</Link>
                <Link to={appNavigation.urlBuilder.profile.ProfileUrl} className="secondary-button">{t("paymentResult.seeAccount")}</Link>
            </ButtonsSection>;
        }
        
        return <ButtonsSection>
            <SquareButton style={{
                                background: `linear-gradient(90deg, ${darkenColor.hex} 0%, ${darkenColor.hex} ${autoCloseState.percentage.toFixed(2)}%, ${theme.primaryColor.hex} ${autoCloseState.percentage.toFixed(2)}%)`,
                                color: "white",
                            }}
                            color={darkenColor} 
                            showShadow
                            isLoading={false}
                            disabled={true}>
                {t("orderAndPayResult.closing")}... ({getSecondsLeft(autoCloseState).toFixed(0)})
            </SquareButton>
            {undefined}
        </ButtonsSection>
    }

    return <Page title={orders.isFirstLoading == false && orders.data.length > 1 ? t("orderAndPayResult.title") : undefined} headerProps={{ hideCart: true, hideOrder: true }} footer={getFooter()}>
        { 
            order != undefined
            ?
            (
                order.state != OrderState.Rejected
                ? 
                    <OrderAndPaySuccess order={order} />
                :
                    <OrderAndPayCanceled order={order} /> 
            )
            :
                <div className="loader-container">
                    <LoadingAnimation />
                </div>
        }
    </Page>
};
export default TrackOrderPage;