import { AnimatePresence } from 'framer-motion';
import { Suspense, useEffect } from "react";
import "react-phone-number-input/style.css";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./App.scss";
import { useAppSelector } from "./app/hooks";
import ExpandedMenu from "./layout/ExpandedMenu";
import Navbar from "./layout/Navbar";
import ConfirmEmail from "./pages/Authentication/ConfirmEmail";
import ForgotPassword from "./pages/Authentication/ForgotPassword";
import ForgotPasswordResult from "./pages/Authentication/ForgotPasswordResult";
import Login from "./pages/Authentication/Login";
import Register from "./pages/Authentication/Register";
import ResetPassword from "./pages/Authentication/ResetPassword";
import ResetPasswordResult from "./pages/Authentication/ResetPasswordResult";
import PhysicalMenuPage from "./pages/PhysicalMenu/PhysicalMenuPage";
import PayPage from "./pages/Pay/PayPage";
import PaymentMethodsPage from "./pages/Pay/PaymentMethodsPage";
import BillSummary from "./pages/Pay/BillSummary";
import SessionChargeCompletedPage from "./pages/PaymentResult/SessionChargeCompletedPage";
import ScanQRCode from "./pages/ScanQRCode/ScanQRCode";
import SplashScreen from "./pages/SplashScreen/SplashScreen";
import ChangePassword from "./pages/UserApp/ChangePassword";
import EditProfile from "./pages/UserApp/EditProfile";
import Settings from "./pages/UserApp/Settings";
import Transactions from "./pages/UserApp/Transactions";
import UserHome from "./pages/UserApp/UserHome";
import DigitalMenuPage from './pages/DigitalMenu/DigitalMenuPage';
import { CartPage } from './pages/DigitalMenu/CartPage';
import OrderCheckoutPage from './pages/Pay/OrderCheckoutPage';
import TrackOrderPage from './pages/PaymentResult/TrackOrderPage';
import ExternalMenu from './pages/ExternalMenu/ExternalMenu';
import { useDeGrazieTheme } from './app/hooks/theme/useDeGrazieTheme';
import { useAppNavigation } from './app/hooks/useAppNavigation';
import PayOrderPage from './pages/PaymentForm/PayOrderPage';
import PaySessionPage from './pages/PaymentForm/PaySessionPage';
import FeaturedDialog from './components/Featured/FeaturedDialog';
import { WalletTopUpPage } from './pages/Wallet/WalletTopUpPage';
import PayTopUpPage from './pages/PaymentForm/PayTopUpPage';
import WalletTopUpDialog from './components/Wallet/WalletTopUpDialog';
import { MasterCardExpandedMenu } from './layout/MasterCardExpandedMenu';
import QrCodeRoute from './components/QrCodeRoute';
import QrCodeProfile from './pages/QrCodeProfile/QrCodeProfile';
import { InactivityDialog } from './layout/InactivityDialog';
import { OrdersPage } from './pages/DigitalMenu/OrdersPage';

const App = () => {
    const theme = useDeGrazieTheme();
    const location = useLocation();
    const appNavigation = useAppNavigation();

    const isAuth = useAppSelector(state => state.user.isAuth);
    const features = useAppSelector(state => state.merchant.features);

    useEffect(() => {
        const onResize = () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };

        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', onResize);
    }, [])

    const getTimestamp = () => {
        const queryTimestamp = new URLSearchParams(location.search).get("timestamp");
        if(queryTimestamp == undefined) {
            return undefined;
        }

        const timestamp = Number(queryTimestamp);
        return isNaN(timestamp) ? undefined : timestamp;
    }

    const getIsFreePayment = () => {
        const isFreePayment = new URLSearchParams(location.search).get("freePayment");
        if(isFreePayment == undefined) {
            return undefined;
        }

        return ["true", "1"].includes(isFreePayment.toLocaleLowerCase());
    }

    return (
    <> 
        <Suspense fallback={<SplashScreen />}>
            <ExpandedMenu />
            <MasterCardExpandedMenu />
        </Suspense>

        <AnimatePresence exitBeforeEnter>
            <Suspense fallback={<SplashScreen />}>
                <Switch location={location} key={location.pathname}>
                    <Route path={appNavigation.urlBuilder.home.HomeUrl().pathname} exact>
                        <QrCodeProfile />
                    </Route>
                    <Route path="/authenticate" strict>
                        {isAuth ? <Redirect to={appNavigation.urlBuilder.profile.ProfileUrl()} /> : <Redirect to={appNavigation.urlBuilder.auth.LoginUrl()}/>}
                    </Route>
                    <Route path={appNavigation.urlBuilder.home.ScanCodeUrl().pathname} exact>
                        <ScanQRCode />
                    </Route>

                    {/* Requires QR Code Context Routes */}
                    <QrCodeRoute path={appNavigation.urlBuilder.menu.ExternalMenu().pathname} exact requiresQrCode={true}>
                        <ExternalMenu />
                    </QrCodeRoute>
                    <QrCodeRoute path={appNavigation.urlBuilder.menu.PhysicalMenu().pathname} exact requiresQrCode={true}>
                        <PhysicalMenuPage />
                    </QrCodeRoute>
                    <QrCodeRoute path={appNavigation.urlBuilder.menu.DigitalMenu().pathname} exact requiresQrCode={true}>
                        <DigitalMenuPage atTimestamp={getTimestamp()}/>
                    </QrCodeRoute>
                    <QrCodeRoute path={appNavigation.urlBuilder.order.Orders().pathname} exact requiresQrCode={true}>
                        <OrdersPage />
                    </QrCodeRoute>
                    <QrCodeRoute path={appNavigation.urlBuilder.order.CartUrl().pathname} exact requiresQrCode={true}>
                        <CartPage />
                    </QrCodeRoute>
                    <QrCodeRoute path={appNavigation.urlBuilder.order.Checkout().pathname} exact requiresQrCode={true}>
                        <OrderCheckoutPage />
                    </QrCodeRoute>
                    <QrCodeRoute path={appNavigation.urlBuilder.payment.PaymentMethodsUrl().pathname} exact requiresQrCode={true}>
                        <PaymentMethodsPage isFreePayment={getIsFreePayment()} />
                    </QrCodeRoute>
                    <QrCodeRoute path={appNavigation.urlBuilder.payment.PaymentSummaryUrl().pathname} exact requiresQrCode={true}>
                        <BillSummary />
                    </QrCodeRoute>
                    <QrCodeRoute path={appNavigation.urlBuilder.payment.PayTotalUrl().pathname} exact requiresQrCode={true}>
                        <PayPage paymentSplit="total" />
                    </QrCodeRoute>
                    <QrCodeRoute path={appNavigation.urlBuilder.payment.ShareCustomUrl().pathname} exact requiresQrCode={true}>
                        <PayPage paymentSplit="custom" />
                    </QrCodeRoute>
                    <QrCodeRoute path={appNavigation.urlBuilder.payment.FreePaymentUrl().pathname} exact requiresQrCode={true}>
                        <PayPage paymentSplit="freepayment" />
                    </QrCodeRoute>
                    <QrCodeRoute path={appNavigation.urlBuilder.payment.ShareEqualUrl().pathname} exact requiresQrCode={true}>
                        <PayPage paymentSplit="equal" />
                    </QrCodeRoute>
                    <QrCodeRoute path={appNavigation.urlBuilder.payment.ShareItemsUrl().pathname} exact requiresQrCode={true}>
                        <PayPage paymentSplit="items" />
                    </QrCodeRoute>

                    <Route path={appNavigation.urlBuilder.payment.PayTopUpPage().pathname}>
                        <PayTopUpPage />
                    </Route>
                    <Route path={appNavigation.urlBuilder.payment.PayPage().pathname}>
                        <PaySessionPage />
                    </Route>
                    <Route path={appNavigation.urlBuilder.payment.PayOrderPage().pathname}>
                        <PayOrderPage />
                    </Route>
                    <Route path={appNavigation.urlBuilder.payment.ChargeCompleted().pathname}>
                        <SessionChargeCompletedPage />
                    </Route>
                    <Route path={appNavigation.urlBuilder.order.TrackOrder().pathname}>
                        <TrackOrderPage />
                    </Route>
                    {
                        !isAuth &&
                        <>
                            <Switch>
                                <Route path={appNavigation.urlBuilder.auth.RegisterUrl().pathname}>
                                    <Register />
                                </Route>
                                <Route path={appNavigation.urlBuilder.auth.LoginUrl().pathname}>
                                    <Login />
                                </Route>
                                <Route path={appNavigation.urlBuilder.auth.ConfirmEmailUrl().pathname}>
                                    <ConfirmEmail />
                                </Route>
                                <Route path={appNavigation.urlBuilder.auth.ForgotPasswordUrl().pathname}>
                                    <ForgotPassword />
                                </Route>
                                <Route path={appNavigation.urlBuilder.auth.ForgotPasswordResultUrl().pathname}>
                                    <ForgotPasswordResult />
                                </Route>
                                <Route path={appNavigation.urlBuilder.auth.ResetPasswordUrl().pathname}>
                                    <ResetPassword />
                                </Route>
                                <Route path={appNavigation.urlBuilder.auth.ResetPasswordResultUrl().pathname}>
                                    <ResetPasswordResult />
                                </Route>
                                <Route path="*" >
                                    <Redirect to={appNavigation.urlBuilder.auth.LoginUrl()} />
                                </Route>
                            </Switch>
                        </>
                    }
                    {
                        isAuth &&
                        <>
                            <Switch>
                                <Route path={appNavigation.urlBuilder.profile.WalletTopUpUrl().pathname} exact>
                                    <WalletTopUpPage />
                                </Route>
                                <Route path={appNavigation.urlBuilder.profile.ProfileUrl().pathname} exact>
                                    <UserHome />
                                </Route>
                                <Route path={appNavigation.urlBuilder.profile.TransactionsUrl().pathname} exact>
                                    <Transactions />
                                </Route>
                                <Route path={appNavigation.urlBuilder.profile.SettingsUrl().pathname} exact>
                                    <Settings />
                                </Route>
                                <Route path={appNavigation.urlBuilder.profile.ChangePasswordUrl().pathname} exact>
                                    <ChangePassword />
                                </Route>
                                <Route path={appNavigation.urlBuilder.profile.EditProfileUrl().pathname} exact>
                                    <EditProfile />
                                </Route>
                                <Route path="*">
                                    <Redirect to={appNavigation.urlBuilder.profile.ProfileUrl().pathname} />
                                </Route>
                            </Switch>
                            <Navbar />
                        </>
                    }
                </Switch>
                
                <WalletTopUpDialog />
                <FeaturedDialog />
                { features.physicalKiosk && <InactivityDialog /> }
            </Suspense>
        </AnimatePresence>
        <ToastContainer
            position="top-center"
            autoClose={10000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={true}
            draggable={false}
            pauseOnHover={false}
            theme="light"
            progressStyle={{
                background: theme.primaryColor.hex,
            }}
        />
    </>
    );
}
export default App;