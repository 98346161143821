import { TextField, Chip, makeStyles, Grid, Checkbox, Box } from "@material-ui/core";
import { Link, Redirect } from "react-router-dom";
import { useCart } from "../../app/hooks/menus/order/useCart";
import { toFormattedPrice } from "../../app/services/format";
import { useAppSelector } from "../../app/hooks";
import MenuItemComponent from "../../components/Menu/MenuItemComponent";
import { useAppNavigation } from "../../app/hooks/useAppNavigation";
import MenuItemDetailDialog from "./MenuItemDetailDialog";
import { useEffect, useMemo, useState } from "react";
import { ICartItem } from "../../app/hooks/menus/order/cartitem";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { format } from "date-fns";
import { SchedulerDialog, SchedulerDialogState } from "../../components/Ordering/SchedulerDialog";
import { useTranslation } from "react-i18next";
import { Page } from "../../layout/Page";
import { ButtonsSection } from "../../layout/ButtonsSection";
import { useQa } from "../../app/hooks/useQa";
import { AvailabilityType } from "../../app/services/api/contracts/models/AvailabilityType";
import { QrCodeCategory } from "../../app/tableSlice";
import Dialog from "../../components/Shared/Dialog";
import { CloseIcon } from "../../components/svgs/CloseIcon";
import { getItemsPrice } from "../../app/helpers/itemsHelper";
import LoadingButton from "../../components/Buttons/LoadingButton";
import { SuccessIcon } from "../../components/svgs/PaymentResultIcons";
import { toast } from "react-toastify";
import { FormControl, Skeleton, Tooltip } from "@mui/material";
import { useOrderFieldsQuery } from "../../app/hooks/orderfields/useOrderFieldsQuery";
import React from "react";
import { OrderFieldType } from "../../app/services/api/contracts/models/OrderField";
import Alert from "../../components/Alerts/Alert";
import { useFormik } from "formik";
import * as Yup from "yup";

const useStyles = makeStyles(() => ({
    observationsContainer: {
        margin: "30px 0",
    },
}));

const getCartItems = (items: ICartItem[]) => {
    const prePaidItemsOnly: ICartItem[] = [];
    const postPaidItemsOnly: ICartItem[] = [];
    const itemsExcludingPostPaidItemsOnly: ICartItem[] = [];

    for(const item of items) {
        if(item.availability == AvailabilityType.PostPayment) {
            postPaidItemsOnly.push(item);
        } else {
            itemsExcludingPostPaidItemsOnly.push(item);
        }

        if(item.availability == AvailabilityType.PrePayment) {
            prePaidItemsOnly.push(item);
        }
    }

    return {
        allItems: items,
        prePaidItemsOnly,
        postPaidItemsOnly,
        itemsExcludingPostPaidItemsOnly,
    };
}

export const CartPage = () => {
    const { i18n, t } = useTranslation(); 
    const { qaTag } = useQa();
    const classes = useStyles();

    const cart = useCart();
    const {
        features,
        qrCodeId
    } = useAppSelector(state => state.merchant);
    const qrCodeCategory = useAppSelector(state => state.table.qrCodeCategory);
    const appNavigation = useAppNavigation();
    const orderFieldsQuery = useOrderFieldsQuery({
        languageIso: i18n.language,
        qrCodeId: qrCodeId,
    });

    const [selectedItem, setSelectedItem] = useState<ICartItem | null>()
    const [schedulerOpen, setSchedulerOpen] = useState(false);
    const [mixPaymentDialogOpen, setMixPaymentDialogOpen] = useState(false);
    const [isPayLaterDialogOpen, setIsPayLaterDialogOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const cartItems = useMemo(() => getCartItems(cart.items), [cart.items])

    const hasPrepaidItemsOnly = cartItems.prePaidItemsOnly.length > 0;
    const hasPostpaidItemsOnly = cartItems.postPaidItemsOnly.length > 0;

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: orderFieldsQuery.data.reduce((result, field) => {
            result[field.id] = field.id in cart.fields ? cart.fields[field.id] : "";

            if(field.type == OrderFieldType.Check) {
                result[field.id] = ![undefined, false, "", "0", "false"].includes(result[field.id]);
            }

            return result;
        }, {} as any),
        validationSchema: Yup.object(orderFieldsQuery.data.reduce((result, field) => {
            let validation = field.type == OrderFieldType.Number 
                                ? 
                                    Yup.number() 
                                :
                                (
                                    field.type == OrderFieldType.Check
                                    ?
                                        Yup.boolean().transform(value => ![undefined, false, "", "0", "false"].includes(cart.fields[field.id]))
                                                    .oneOf(field.isRequired ? [true] : [false, true], t("form.requiredField"))
                                    :
                                        Yup.string()
                                );

            if(field.isRequired) {
                validation = validation.required(t("form.requiredField"));
            }
            
            result[field.id] = validation;
            return result;
        }, {} as any)),
        onSubmit: (values) => cart.editFields(values),
        initialStatus: false,
    });

    useEffect(() => {
        if(isSubmitting == false) {
            return;
        }

        cart.submit(true).then(() => {
            toast.info(t("digitalMenu.receivedOrderMsg"), {
                icon: <SuccessIcon />,
            });
            appNavigation.goTo(urlBuilder => urlBuilder.payment.PaymentSummaryUrl());
        }).finally(() => setIsSubmitting(false))
    }, [isSubmitting])

    const getDate = (scheduledDate: Date | undefined) => {
        if(scheduledDate == undefined) {
            return t("orderScheduling.asSoonAsPossible");
        }

        const day = scheduledDate.getDate();
        const month = t(`calendar.months.${scheduledDate.getMonth() + 1}`);
        const time = format(scheduledDate, "HH:mm");

        return t("calendar.dateFormat", { day: day, month: month, time: time});
    }

    const getPrePaidButton = () => {
        const prePaidTotal = getItemsPrice(cartItems.itemsExcludingPostPaidItemsOnly);
        const remainingAmount = features.ordering.minimumPrePaidOrderAmount - prePaidTotal;
        const isDisabled = remainingAmount > 0;
        return <LoadingButton
                key="prepay"
                className="primary-button w-100"
                isLoading={orderFieldsQuery.isFirstLoading || formik.isSubmitting}
                {...qaTag('go-to-checkout')}
                onClick={async () => {
                    formik.setStatus(true);
                    const result = await formik.validateForm();
                    if(Object.keys(result).length > 0) {
                        return;
                    }
                    await formik.handleSubmit();
                    appNavigation.goTo(a => a.order.Checkout())
                }}
                disabled={formik.isValid == false || isDisabled}
            >
                {
                    isDisabled
                    ?
                    <Tooltip
                        title={t("cart.amountNotEligible", { 
                            amount: toFormattedPrice(features.ordering.minimumPrePaidOrderAmount), 
                            remaining: toFormattedPrice(remainingAmount),
                        })}
                        placement="top" 
                        arrow
                        open
                        PopperProps={{className: "Mastercard MuiTooltip-popper MuiTooltip-popperArrow"}}
                    >
                        <span>
                            {t("pay.payNow")}
                        </span>
                    </Tooltip>
                    :
                    t("pay.payNow")
                }
            </LoadingButton>
    }

    const getFooter = () => {
        const buttons: React.ReactNode[] = [];
        if(cart.totalItems == 0) {
            buttons.push(<Link key="see-menu" className="primary-button w-100" to={appNavigation.urlBuilder.menu.DigitalMenu()}>
                {t("cart.seeMenu")}
            </Link>)
            return <ButtonsSection children={buttons} />
        } 

        const payLaterButton = <LoadingButton
                                    key="pay-later"
                                    className="primary-button w-100"
                                    onClick={(async () => {
                                        formik.setStatus(true);
                                        const result = await formik.validateForm();
                                        if(Object.keys(result).length > 0) {
                                            return;
                                        }
                                        await formik.handleSubmit();
                                        setIsPayLaterDialogOpen(true);
                                    })}
                                    disabled={formik.isValid == false}
                                    isLoading={orderFieldsQuery.isFirstLoading}
                                >
                                    {
                                        qrCodeCategory == QrCodeCategory.Room
                                        ?
                                            t("pay.addConsumption")
                                        :
                                            t("pay.payLater")
                                    }
                                </LoadingButton>;
        const prePayButton = getPrePaidButton();

        if(hasPrepaidItemsOnly && hasPostpaidItemsOnly) {
            buttons.push(<LoadingButton key="mix" isLoading={orderFieldsQuery.isFirstLoading} className="primary-button w-100" onClick={() => setMixPaymentDialogOpen(true)}>
                {t("cart.checkout")}
            </LoadingButton>)
        } else if(hasPostpaidItemsOnly) {
            if(features.ordering.allowsPostPaidOrdering) {
                buttons.push(payLaterButton)
            }
        } else if(hasPrepaidItemsOnly) {
            if(features.ordering.allowsPrePaidOrdering) {
                buttons.push(prePayButton)
            }
        } else {
            if(features.ordering.allowsPostPaidOrdering) {
                buttons.push(payLaterButton)
            }
            if(features.ordering.allowsPrePaidOrdering) {
                buttons.push(prePayButton)
            }
        }
        return <ButtonsSection children={buttons} />
    }

    if(features.ordering.isActive == false) {
        return <Redirect to={appNavigation.urlBuilder.home.HomeUrl()} />
    }

    return <Page title={t("cart.title")} headerProps={{hideCart: true}} footer={getFooter()}>
        { 
            cartItems.allItems.length > 0 
            ? // Cart contains items
            <>
                {
                    hasPrepaidItemsOnly && hasPostpaidItemsOnly ?
                    <>
                        <CartResume items={cartItems.postPaidItemsOnly} onItemSelected={setSelectedItem} totalDescription={t("cart.payingLater")} />
                        <div className="mt-8" />
                        <CartResume items={cartItems.itemsExcludingPostPaidItemsOnly} onItemSelected={setSelectedItem} totalDescription={t("cart.payingNow")}/>
                    </>
                    :
                        <CartResume items={cartItems.allItems} onItemSelected={setSelectedItem} />
                }
                {
                    orderFieldsQuery.data.length > 0 &&
                    <div className={classes.observationsContainer}>
                    {
                        orderFieldsQuery.data.map(f => (
                            <FormControl 
                                    key={f.id} sx={{
                                        mb: "0.5rem",
                                        display: "flex",
                                        flexDirection: "column"
                                    }}
                            >
                                <Box
                                    sx={
                                        f.type == OrderFieldType.Check ?
                                        {
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                        }
                                        :
                                        undefined
                                    }
                                >
                                    <label
                                        htmlFor={f.id}
                                        style={
                                            f.type == OrderFieldType.Check
                                            ?
                                            {
                                                textAlign: "center",
                                                alignContent: "center",
                                            }
                                            : 
                                            {}
                                        }
                                    >
                                        {f.name} ({f.isRequired ? t("form.mandatory") : t("optional")})
                                    </label>
                                    {
                                        cart.isInitializing
                                        ?
                                        <Skeleton animation="wave" width="100%" />
                                        :
                                        (
                                            f.type == OrderFieldType.Check
                                                ?
                                                <Checkbox
                                                    id={f.id}
                                                    name={f.id}
                                                    checked={!!formik.values[f.id]}
                                                    value={!!formik.values[f.id]}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    color="primary"
                                                />
                                                :
                                                <TextField
                                                    id={f.id}
                                                    name={f.id}
                                                    value={formik.values[f.id]}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}

                                                    variant="outlined"
                                                    color="primary"
                                                    autoComplete="off"
                                                    type={f.type ? "number" : "text"}
                                                    required={f.isRequired}
                                                    multiline={f.type == OrderFieldType.LongText}
                                                    minRows={f.type == OrderFieldType.LongText ? 3 : undefined}
                                                    inputProps={{ maxLength: 1000 }} 
                                                />
                                        )
                                    }
                                </Box>
                                {(formik.touched[f.id] || formik.status == true) && formik.errors[f.id] && <Alert message={formik.errors[f.id]?.toString() ?? ""} />}
                            </FormControl>
                        ))
                    }
                    </div>
                }
                <div className="mt-5">
                    {
                        features.ordering.isActive && features.ordering.allowScheduling &&
                        <Chip
                            onClick={() => setSchedulerOpen(true)}
                            avatar={<AccessTimeIcon />}
                            label={<p>{getDate(cart.scheduledDate)}</p>} 
                            variant="outlined"
                            size="medium"
                            style={{width: "100%", cursor: "pointer", fontFamily: "Poppins, sans-serif"}}
                        />                     
                    }
                </div>
            </>
            : // No items in cart
            <>
                <div className="flex flex-fd-c flex-ai-c flex-jc-c mt-10">
                    <h2 className="mb-4">{t("cart.cartIsEmpty")}</h2>
                    <p className="ta-c">{t("cart.cartIsEmptyDesc")}</p>
                </div>
            </>
        }
        <MenuItemDetailDialog menuItem={selectedItem ?? null} onClose={() => setSelectedItem(undefined)} />
        <SchedulerDialog date={cart.scheduledDate}
                            isOpen={schedulerOpen} 
                            onDialogChange={(s) => s == SchedulerDialogState.Closed && setSchedulerOpen(false)} 
                            onDateSelected={() => setSchedulerOpen(false)}/>
        <Dialog isOpen={mixPaymentDialogOpen} onClose={() => setMixPaymentDialogOpen(false)}>
            <div className="container" style={{ paddingTop: "1.75rem", paddingBottom: "1.75rem" }}>
                <div className="modal__header">
                    <h3>{t("cart.mixedPaymentTitle")}</h3>
                    <div className="close-icon" onClick={() => setMixPaymentDialogOpen(false)}>
                        <CloseIcon />
                    </div>
                </div>

                <p className="mb-5">{t("cart.mixedPaymentDescription", {
                    totalNow: toFormattedPrice(getItemsPrice(cartItems.itemsExcludingPostPaidItemsOnly), "€"),
                    totalLater: toFormattedPrice(getItemsPrice(cartItems.postPaidItemsOnly), "€"),
                })}</p>

                <LoadingButton isLoading={false} className="primary-button mt-5" onClick={() => setMixPaymentDialogOpen(false)}>
                    {t("back")}
                </LoadingButton>
            </div>
        </Dialog>
        <Dialog isOpen={isPayLaterDialogOpen || isSubmitting} onClose={() => setIsPayLaterDialogOpen(false)}>
            <div className="container" style={{ paddingTop: "1.75rem", paddingBottom: "1.75rem" }}>
                <div className="modal__header">
                    <h3>{t("cart.confirmSubmissionTitle")}</h3>
                    <div className="close-icon" onClick={() => setIsPayLaterDialogOpen(false)}>
                        <CloseIcon />
                    </div>
                </div>

                <p className="mb-5">{t("cart.confirmSubmissionDescription")}</p>

                <ButtonsSection>
                    <LoadingButton isLoading={false} className="secondary-button w-100" onClick={() => setIsPayLaterDialogOpen(false)}>
                        {t("cart.payLater.change")}
                    </LoadingButton>
                    <LoadingButton isLoading={isSubmitting} className="primary-button w-100" onClick={() => setIsSubmitting(true)}>
                        {t("cart.payLater.submit")}
                    </LoadingButton>
                </ButtonsSection>
            </div>
        </Dialog>
    </Page>
}

interface CartResumeProps {
    readonly items: ICartItem[];
    readonly onItemSelected: (item: ICartItem) => any;
    readonly totalDescription?: string;
}
const CartResume = (props: CartResumeProps) => {
    const { t } = useTranslation(); 
    const total = useMemo(() => getItemsPrice(props.items), [props.items]);

    const getItemId = (item: ICartItem) => {
        if(!!item.modifiers && item.modifiers.length > 0) {
            const allSelectedOptions = item.modifiers.map(m => m.selectedOptions).reduce((r, o) => [...r, ...o], []);
            return allSelectedOptions.map(m => {
                let result = m.id;
                for(let i = 0; i < m.quantity; ++i) {
                    result = `${result}-${m.id}`;
                }
                return result;
            }).reduce((r, id) => `${r}-${id}`, item.id);
        }
        return item.id;
    }
    
    return <>
        <Grid container spacing={2}>
        {
            props.items.map(item => <Grid item key={getItemId(item)} style={{width: "100%"}}>
                <MenuItemComponent menuItem={item} quickCartAlwaysOpened exactItemMatch onItemSelected={() => props.onItemSelected(item)}/>
            </Grid>)
        }
        </Grid>

        <div className="cart__total-price mt-4">
            <div>
                <h2 className="title">{t("cart.totalPrice")}</h2>
                { props.totalDescription != undefined && <h4 className="title">{props.totalDescription}</h4> }
            </div>
            <h2 className="amount">{toFormattedPrice(total, "€")}</h2>
        </div>
    </>
}