import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toFormattedPrice } from "../../app/services/format";

interface Props {
    readonly loadUserBill: (userBill: number) => void;
    readonly tablePending: number | null;
}

const PayTotal: React.FC<Props> = ({ 
    loadUserBill,
    tablePending,
}) => {
    const { t } = useTranslation();
    
    useEffect(() => {
        if(tablePending == null) {
            return;
        }

        loadUserBill(tablePending);
    }, [tablePending]);

    return (
        <div className={"total-container"}>
            <h2 className="mb-1">{t("pay.payTotal")}</h2>
            <h1>{toFormattedPrice(tablePending ?? 0, "€")}</h1>
        </div>
    )
};

export default PayTotal;