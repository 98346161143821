import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { LOW_AMOUNT_PAYMENT, SECOND_TIER_TIP } from "../../app/constants";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { SyncState, useSession } from "../../app/hooks/session/useSession";
import { TipsOptions, TipsOptionsConfiguration } from "../../app/models/tipsOptions";
import { setPaymentDetails } from "../../app/paymentSlice";
import { useBrowserStorageService } from "../../app/hooks/useBrowserStorageService";
import { calculateTip, calculateUserTotal, roundDecimalsUp } from "../../app/services/calculations";
import { formatToCleanString, formatTotal } from "../../app/services/format";
import { vatValidation } from "../../app/services/inputValidators";
import { PaymentDetails, payAtTheTable } from "../../app/types/paymentTypes";
import Alert from "../../components/Alerts/Alert";
import { InfoIcon } from "../../components/svgs/InfoIcon";
import PayTotal from "./PayTotal";
import ShareCustom from "./ShareCustom";
import ShareEqual from "./ShareEqual";
import ShareItems from "./ShareItems";
import TipSection from "./TipSection/TipSection";
import { useDeGrazieTheme } from "../../app/hooks/theme/useDeGrazieTheme";
import { useAppNavigation } from "../../app/hooks/useAppNavigation";
import { Grid, Tooltip } from "@material-ui/core";
import Dialog from "../../components/Shared/Dialog";
import { CloseIcon } from "../../components/svgs/CloseIcon";
import { Page } from "../../layout/Page";
import { useQa } from "../../app/hooks/useQa";
import { useFeesQuery } from "../../app/hooks/fees/useFeesQuery";
import { useMastercard } from "../../app/hooks/mastercard/useMastercard";
import { ChargeMethod } from "../../app/services/api/contracts/models/ChargeMethod";

interface Props {
    readonly paymentSplit: "total" | "equal" | "custom" | "items" | "freepayment";
}

const PayPage: React.FC<Props> = ({ paymentSplit }) => {
    const browserStorageService = useBrowserStorageService();
    const { t } = useTranslation();
    const { qaTag } = useQa();
    const dispatch = useAppDispatch();
    const theme = useDeGrazieTheme();
    const appNavigation = useAppNavigation();
    
    const userAuth = useAppSelector(state => state.user.isAuth);
    const fees = useFeesQuery();
    const mastercard = useMastercard();
    
    const [session, sessionState] = useSession();
    const userVat = useAppSelector(state => state.user.vatNumber);
    const userEmail = useAppSelector(state => state.user.userEmail);
    const paymentDetails = useAppSelector(state => state.payment.paymentDetails);
    const paymentDivision = useAppSelector(state => state.payment.paymentDivision);
    const { features, qrCodeId } = useAppSelector(state => state.merchant);

    const [tablePending, setTablePending] = useState<number | null>(sessionState == SyncState.Loading ? null : roundDecimalsUp(session.unpaidAmount));
    const [vatIsValid, setVatIsValid] = useState(true);
    const [userBill, setUserBill] = useState(0); // Amount of the bill the user wants to pay
    const [tipValue, setTipValue] = useState(paymentDetails.tip);
    const [selectedTipOption, setSelectedTipOption] = useState(paymentDetails.selectedTip);
    const [selectedItems, setSelectedItems] = useState(payAtTheTable(paymentDetails.additionalData)?.orders || []);
    const [tipModalIsOpen, setTipModalIsOpen] = useState(false);

    const isTipOnly = paymentSplit == "freepayment" && features.freePayments.isTipOnly;
    const isOverPaying = () => paymentSplit != "freepayment" && (userBill > (tablePending ?? 0));
    const getUserTotal = () => calculateUserTotal(roundDecimalsUp(userBill), tipValue);

    const handleAmountResult = (userBillAmount: number) => {
        if(isTipOnly) {
            setUserBill(0);
            setTipValue(userBillAmount);
            return;
        }
        
        setUserBill(userBillAmount);
        let tip: number;
        if (!userBillAmount || selectedTipOption.id === TipsOptions.empty().id) {
            tip = 0;
        } else if (selectedTipOption.id === TipsOptions.otherButton().id) {
            tip = tipValue;
        } else if (userBillAmount < SECOND_TIER_TIP) {
            tip = selectedTipOption.fisrtTierFixedTip;
            setTipValue(tip);
        } else if (userBillAmount >= SECOND_TIER_TIP && userBillAmount < LOW_AMOUNT_PAYMENT) {
            tip = selectedTipOption.secondTierFixedTip;
            setTipValue(tip);
        } else {
            tip = calculateTip(userBillAmount, selectedTipOption.percentage);
            setTipValue(tip);
        }
    };

    const formik = useFormik({
        initialValues: {
            email: userEmail || paymentDetails.email || "",
            vat: userVat || paymentDetails.vatNumber || "",
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email(t("form.emailValidation")),
        }),
        onSubmit: async (values) => {
            const newPaymentDetails: PaymentDetails = {
                total: getUserTotal(),
                amount: roundDecimalsUp(userBill),
                tip: tipValue,
                email: values.email,
                vatNumber: formatToCleanString(values.vat),
                additionalData: {
                    orders: selectedItems,
                },
                selectedTip: selectedTipOption,
            };

            browserStorageService.savePaymentDivision(JSON.stringify(paymentDivision));
            browserStorageService.savePaymentDetails(JSON.stringify(newPaymentDetails));
            dispatch(setPaymentDetails(newPaymentDetails))

            setTablePending(0);
            appNavigation.goTo(urlBuilder => urlBuilder.payment.PaymentMethodsUrl(paymentSplit == "freepayment"));
            return;
        }
    });

    useEffect(() => {
        if(sessionState == SyncState.Loading) {
            return;
        }

        if (!session.id && ["custom", "freepayment"].includes(paymentSplit) == false) {
            appNavigation.goBack(u => u.home.HomeUrl(qrCodeId));
            return;
        }

        if(tablePending == null) {
            setTablePending(roundDecimalsUp(session.unpaidAmount))
        }

    }, [sessionState]);

    const submitForm = () => {
        if (paymentSplit != "freepayment" && session.isAmountPayable(userBill) == false) {
            appNavigation.goBack(urlBuilder => urlBuilder.payment.PaymentSummaryUrl());
            toast.info(t("pay.sessionHasUpdates"), {
                icon: <InfoIcon color={theme.primaryColor.hex} />,
            });
            return;
        }
        formik.handleSubmit();
    }

    const onPayClick = () => {
        if(tipValue > 0 || features.payAtTheTable.enforceTip == false) {
            submitForm();
            return;
        }

        setTipModalIsOpen(true);
    }

    const getFooter = () => {
        const disabled = isTipOnly ? tipValue == 0 : userBill === 0 || isOverPaying();
        return <input
            {...qaTag('pay')}
            type="button"
            onClick={onPayClick}
            value={
                userBill > 0
                    ? `${t("pay.pay")} ${formatTotal(getUserTotal())} €`
                    : t("pay.pay")
            }
            className={`primary-button ${disabled ? "disabled" : ""}`}
        />
    }
    return <Page title={t("pay.title")} footer={getFooter()}>
        <section className="pay" style={{marginBottom: 0}}>
            <form>
                {paymentSplit === "total" &&
                    <PayTotal loadUserBill={handleAmountResult} tablePending={tablePending} />
                }
                {paymentSplit === "equal" &&
                    <ShareEqual onChangeAmount={handleAmountResult} tablePending={tablePending} />
                }
                {paymentSplit === "custom" &&
                    <ShareCustom onChangeAmount={handleAmountResult} tablePending={tablePending ?? undefined} />
                }
                {paymentSplit === "freepayment" &&
                    <ShareCustom onChangeAmount={handleAmountResult} />
                }
                {paymentSplit === "items" &&
                    <ShareItems onChangeAmount={handleAmountResult} tablePending={tablePending} onChangeItems={selectedItemsArr => setSelectedItems(selectedItemsArr)} />
                }

                {
                    isTipOnly == false &&
                    <TipSection
                        userBill={userBill}
                        setTipResult={setTipValue}
                        setTipChoice={(tipOption: TipsOptionsConfiguration) => setSelectedTipOption(tipOption)}
                        selectedTip={selectedTipOption}
                    />
                }

                <div className="pay__receipt mb-6">
                    <Grid container spacing={1} style={{justifyContent: "center"}}>
                        <Grid xs={12} sm={12} md={6} lg={6} xl={6} item className="pay__email">
                            {
                                !tipModalIsOpen && 
                                !userAuth && 
                                mastercard.isAvailable && 
                                fees.isLoading == false && 
                                mastercard.minEligibleAmount <= fees.getAmountWithFee(getUserTotal(), ChargeMethod.CreditCard) 
                                ?
                                    <div className="pay__email">
                                        <label htmlFor="email">{t("form.invoiceEmail")}</label>
                                        <Tooltip PopperProps={{className: "Mastercard MuiTooltip-popper MuiTooltip-popperArrow"}} title={t("mastercardCampaign.enterEmailToBeElligible")} placement="top" arrow open>
                                            <input type="email" id="email" name="email" placeholder={t("form.emailPlaceholder")} value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                        </Tooltip>
                                        {formik.touched.email && formik.errors.email && <Alert message={formik.errors.email} />}
                                    </div>
                                :
                                    <div className="pay__email">
                                        <label htmlFor="email">{t("form.invoiceEmail")}</label>
                                        <input type="email" id="email" name="email" placeholder={t("form.emailPlaceholder")} value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                        {formik.touched.email && formik.errors.email && <Alert message={formik.errors.email} />}
                                    </div>
                            }
                        </Grid>
                        <Grid xs={12} sm={12} md={6} lg={6} xl={6} item className="pay__nif">
                            <label htmlFor="vat">{t("form.vat")} ({t("optional")})</label>
                            <input type="tel" id="vat" name="vat" placeholder={t("form.vatPlaceholder")}
                                value={formik.values.vat} onChange={formik.handleChange}
                                onBlur={(e: React.FocusEvent) => {
                                    setVatIsValid(vatValidation(formik.values.vat));
                                    formik.handleBlur(e);
                                }} 
                            />
                            {formik.touched.vat && !vatIsValid && <Alert message={t("form.vatValidation") } />}
                        </Grid>
                    </Grid>
                </div>
            </form>
        </section>
        <Dialog
            onClose={() => setTipModalIsOpen(false)}
            isOpen={tipModalIsOpen}
        >
            <div className="container" style={{ paddingTop: "1.75rem", paddingBottom: "1.75rem" }}>
                <div className="modal__header">
                    <h3>{t("paymentMethods.tipRecall")}</h3>
                    <div className="close-icon" onClick={() => setTipModalIsOpen(false)}>
                        <CloseIcon />
                    </div>
                </div>

                <p className="mb-5">{t("paymentMethods.tipRecallDescription")}</p>

                {
                    tipValue == 0 &&
                    <button type="button" className="secondary-button" onClick={() => formik.handleSubmit()}>{t("paymentMethods.noTip")}</button>
                }
                
                <br/>

                <TipSection
                    userBill={userBill}
                    setTipResult={setTipValue}
                    setTipChoice={(tipOption: TipsOptionsConfiguration) => setSelectedTipOption(tipOption)}
                    selectedTip={selectedTipOption}
                />

                <button
                    {...qaTag('continue-to-payment-methods')}
                    type="button"
                    className={`primary-button mt-5 ${tipValue == 0 && "primary-button--inactive"}`}
                    onClick={() => submitForm()}>{t("paymentMethods.continue")}
                </button>
            </div>
        </Dialog>
    </Page>
};

export default PayPage;
